<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="white-space: nowrap;font-weight: 700;text-align: right;width: 80px;">关键字：</label>
				<el-input v-model="searchParams.Keywords" placeholder="公司名称" style="width: 250px;"></el-input>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="white-space: nowrap;font-weight: 700;text-align: right;width: 80px;">线索来源：</label>
				<el-select v-model="searchParams.CRMClueFromId" placeholder="请选择" clearable style="width:200px;margin-right:10px">
				    <el-option v-for="item in searchData.sourceList" :key="item.Id" :label="item.FromName" :value="item.Id">
						<div style="max-width: 160px;" class="ellipsis">{{item.FromName}}</div>
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label" style="white-space: nowrap;font-weight: 700;text-align: right;width: 80px;">跟进状态：</label>
				<el-select v-model="searchParams.FollowUpState" placeholder="请选择" clearable style="width:200px;margin-right:10px">
				    <el-option label="全部" :value="null"></el-option>
					<el-option label="待跟进" :value="1"></el-option>
					<el-option label="跟进中" :value="2"></el-option>
					<el-option label="已转换" :value="3"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label" style="white-space: nowrap;font-weight: 700;text-align: right;width: 80px;">负责人：</label>
				<el-select v-model="searchParams.MallEmployeeId" placeholder="请选择" filterable clearable style="width:200px;margin-right:10px">
				    <el-option v-for="item in searchData.employeeList" :key="item.Id" :label="item.label" :value="item.Id"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="handleSearch">查询</el-button>
				<el-button type="text" style="margin-left:20px;" @click="handleReset">重置筛选条件</el-button>
			</div>
		</div>

		<el-table :data="list" v-loading="loading" ref="multipleTable" class="clue-table" row-key="Id">
			<el-table-column prop="ClueNo" label="线索编码" width="160px" fixed="left">
				<template slot-scope="scope">
					<div class="flex flex-align-center ellipsis" style="color: #409EFF;cursor: pointer;" @click="handleToDetails(scope.row)">
						{{ scope.row.ClueNo }}
						<div class="tag" v-if="scope.row.IsRepeat">重复</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="CompanyName" label="公司名称" width="220px" fixed="left">
				<template slot-scope="scope">
					<el-popover
						placement="bottom"
						title=""
						width="200"
						trigger="hover"
						:content="scope.row.CompanyName">
						<div class="ellipsis" slot="reference" style="color: #409EFF;cursor: pointer;" @click="handleToDetails(scope.row)">
							{{ scope.row.CompanyName }}
						</div>
					  </el-popover>
					
				</template>
			</el-table-column>
			<el-table-column prop="CRMClueFromName" label="线索来源" width="120px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.CRMClueFromName" class="ellipsis">
							{{ scope.row.CRMClueFromName }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="FollowUpStateValue" label="跟进状态" width="90px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.FollowUpStateValue" class="ellipsis">
							{{ scope.row.FollowUpStateValue }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="EmployeeName" label="负责人" width="130px">
				<template slot-scope="scope">
					<div>
						<div class="gap-10" v-if="scope.row.EmployeeName || scope.row.EmployeePhone">
							<div class="ellipsis" style="white-space: nowrap;">{{ scope.row.EmployeeName || '--' }}</div>
							<div class="ellipsis" style="white-space: nowrap;">{{ scope.row.EmployeePhone || '--' }}</div>
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="CustomerCompanyName" label="客户" width="180px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.CustomerCompanyName" class="ellipsis" style="color: #409EFF;cursor: pointer;" @click="handleToCompanyDetails(scope.row.CustomerId)">
							{{ scope.row.CustomerCompanyName }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="SellRemark" label="销售备注" width="180px">
				<template slot-scope="scope">
					<div>
						<el-popover
							v-if="scope.row.SellRemark"
							placement="bottom"
							title=""
							width="400"
							trigger="hover"
							:content="scope.row.SellRemark">
							<div class="ellipsis" slot="reference">
								{{ scope.row.SellRemark }}
							</div>
						  </el-popover>
						
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="ContactName" label="联系人" width="130px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.ContactName || scope.row.ContactPhone">
							<div class="ellipsis">{{ scope.row.ContactName || '--' }}</div>
							<div class="ellipsis">{{ scope.row.ContactPhone || '--' }}</div>
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="LandlinePhone" label="固话" width="140px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.LandlinePhone" class="ellipsis">
							<span v-if="scope.row.LandlinePhoneAreaCode">{{ scope.row.LandlinePhoneAreaCode }}-</span>{{ scope.row.LandlinePhone }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="Province" label="所在地区" width="200px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.Province" class="ellipsis">
							{{ scope.row.Province }}/{{ scope.row.City }}/{{ scope.row.Area }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="Address" label="详细地址" width="200px">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.Address" class="ellipsis">
							{{ scope.row.Address }}
						</div>
						<div v-else>
							--
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="AddTime" label="添加时间" width="160px">
			</el-table-column>
			<el-table-column prop="" label="操作" width="240px" fixed="right">
				<template slot-scope="scope">
					<div class="flex flex-align-center flex-wrap gap-10">
						<div>
							<el-button type="text" @click="handleToDetails(scope.row)">详情</el-button>
						</div>
						<div v-if="scope.row.FollowUpState == 1">
							<buttonPermissions :datas="'sellClueChangeFollowUpState'" >
								<el-button type="text" @click="handleChangeFollowUpState(scope.row)">跟进中</el-button>
							</buttonPermissions>
						</div>
						<div v-if="scope.row.AllocateState === 2 && scope.row.FollowUpState !== 3">
							<buttonPermissions :datas="'sellClueChangeLeader'" >
								<el-button type="text" @click="handleChangeLeader(scope.row)">更换负责人</el-button>
							</buttonPermissions>
						</div>
						<div v-if="scope.row.FollowUpState !== 3">
							<buttonPermissions :datas="'sellClueRepeal'" >
								<el-button type="text" @click="handleRepeal(scope.row)">作废</el-button>
							</buttonPermissions>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="page-box">
			<div class="left">
			</div>
			<el-pagination style="text-align:right" class="margin-top-10" v-if="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		
		
		
		<!-- 作废线索 -->
		<el-dialog class="clue-dialog" title="作废线索" :visible.sync="repeal.visible" width="450px">
			<el-form :model="repeal.form" :rules="repeal.rules" label-width='88px' ref="repealForm">
				<el-form-item label="作废原因:" prop="CancelType">
					<el-select v-model="repeal.form.CancelType" placeholder="请选择" style="width:311px;">
					    <el-option label="客户暂时无需求" :value="1"></el-option>
						<el-option label="联系不上客户" :value="2"></el-option>
						<el-option label="其他" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" prop="CancelTypeRemark" v-if="repeal.form.CancelType===3">
					<el-input placeholder="" v-model="repeal.form.CancelTypeRemark" style="width: 311px;" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="备注:" prop="CancelRemark">
					<el-input type="textarea" :rows="3" v-model="repeal.form.CancelRemark" resize="none" style="width: 311px;" maxlength="500"></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="repeal.visible=false">关闭</el-button>
				<el-button type="primary" :loading="repeal.loading" @click="handleRepealSave">确定</el-button>
			</div>
		</el-dialog>
		
		<!-- 更换销售负责人 -->
		<el-dialog class="clue-dialog" title="更换销售负责人" :visible.sync="changeLeader.visible" width="450px">
			<el-form :model="changeLeader.form" :rules="changeLeader.rules" :inline="true" ref="changeLeaderForm">
				<el-form-item label="将负责人更换为：" filterable prop="MallEmployeeId">
					<el-select v-model="changeLeader.form.MallEmployeeId" placeholder="请选择" style="width:398px;">
					    <el-option v-for="item in searchData.employeeList" :key="item.Id" :label="item.label" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="changeLeader.visible=false">关闭</el-button>
				<el-button type="primary" :loading="changeLeader.loading" @click="handleChangeLeaderSave">确定</el-button>
			</div>
		</el-dialog>
		
		
	</div>
</template>

<script>
	
	import {
		cRMClueSellList,
		cRMClueCancel,
		cRMClueAllocate,
		cRMClueFollowUp,
		cRMClueAllocateJudgeState,
		cRMClueChangeMallEmployeeId,
		cRMClueFromFilterBoxList,
	} from '@/api/cluesManage';
	
	import {
		employeelistbystate
	} from '@/api/sv1.0.0.js';
	
	import config from '@/config/index';
	
	import buttonPermissions from '@/components/buttonPermissions';
	
	export default {
		components: {buttonPermissions},
		data() {
			return {
				//列表
				list: [],
				total: 0,
				pageIndex: 1,
				pageSize: 20,
				loading: false,
				
				//搜索
				searchData: {
					sourceList: [{
						Id: null,
						FromName: '全部'
					}],
					employeeList: []
				},
				searchParams: {
					Keywords: '',
					CRMClueFromId: null,
					FollowUpState: null,
					MallEmployeeId: ''
				},
				
				//作废
				repeal: {
					visible: false,
					loading: false,
					form: {
						CancelType: '',
						CancelTypeRemark: '',
						CancelRemark: ''
					},
					rules: {
						CancelType: {
							required: true,
							message: '请选择作废原因',
							trigger: 'change'
						}
					}
				},
				
				//更换销售负责人
				changeLeader: {
					visible: false,
					loading: false,
					form: {
						MallEmployeeId: ''
					},
					rules: {
						MallEmployeeId: {
							required: true,
							message: '请选择负责人',
							trigger: 'change'
						}
					}
				},
				
			}
		},
		methods: {
			async getList(){
				this.loading = true;
				
				try{
					let params = {
						Skip: (this.pageIndex-1) * this.pageSize,
						Take: this.pageSize,
						...this.searchParams
					};
					const res = await cRMClueSellList(params);
					this.list = res.Result.Results;
					this.total = res.Result.Total;
					this.loading = false;
				}catch(e){
					this.loading = false;
				}
			},
			
			/**
			 *  获取在职员工列表
			 */
			async getEmployeeList(){
				try{
					const res = await employeelistbystate({EmployeeState: -1});
					this.searchData.employeeList = res.Result.map(item=>{
						item.label = item.EmployeeName + '(' + item.Phone + ')';
						return item;
					});
				}catch(e){
					console.log(e)
				}
			},

			
			/**
			 * 分页
			 */
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
			
			/**
			 * 搜索
			 */
			handleSearch(){
				this.pageIndex = 1;
				this.getList();
			},
			handleReset(){
				this.searchParams.Keywords = '';
				this.searchParams.CRMClueFromId = null;
				this.searchParams.FollowUpState = null;
				this.searchParams.MallEmployeeId = '';
				this.handleSearch();
			},
			
			/**
			 * 获取线索来源列表
			 */
			async getSourceList(){
				try{
					const res = await cRMClueFromFilterBoxList({});
					this.searchData.sourceList.push(...res.Result);
				}catch(e){
				}
			},
			
			/**
			 * 添加编辑线索
			 */
			editClue(record){
				this.$router.push({
					name: 'clueDataEdit',
					query: {
						id: record ? record.Id : ''
					}
				})
			},
			
			/**
			 * 详情
			 */
			handleToDetails(record){
				this.$router.push({
					name: 'clueDataDetails',
					query: {
						id: record.Id,
						key: 'sellClue'
					}
				})
			},
			
			/**
			 * 跳转客户详情
			 */
			handleToCompanyDetails(Id){
				this.$router.push({
					name: 'SellCustomerDetail',
					query: { Id }
				})
			},
			
			/**
			 *  作废
			 */
			handleRepeal(e){
				this.selectData = e;
				this.repeal.visible = true;
				this.$nextTick(()=>{
					this.$refs['repealForm'].resetFields()
				})
			},
			handleRepealSave(){
				this.repeal.loading = true;
				this.$refs['repealForm'].validate((valid) => {
					if (valid) {
						this.sureRepeal();
					}else{
						this.repeal.loading = false;
					}
				});
			},
			async sureRepeal(){
				try{
					await cRMClueCancel({ Id: this.selectData.Id, ...this.repeal.form });
					this.repeal.visible = false;
					this.$message.success('操作成功');
					this.getList();
				}catch(e){
					//TODO handle the exception
				}
				
				this.repeal.loading = false;
			},
			
			
			/**
			 * 更换负责人
			 */
			handleChangeLeader(e){
				this.selectData = e;
				this.changeLeader.visible = true;
				this.$nextTick(()=>{
					this.$refs['changeLeaderForm'].resetFields()
				})
			},
			handleChangeLeaderSave(){
				this.changeLeader.loading = true;
				this.$refs['changeLeaderForm'].validate((valid) => {
					if (valid) {
						this.sureChangeLeader();
					}else{
						this.changeLeader.loading = false;
					}
				});
			},
			async sureChangeLeader(){
				try{
					await cRMClueChangeMallEmployeeId({ Id: this.selectData.Id, ...this.changeLeader.form });
					this.changeLeader.visible = false;
					this.$message.success('操作成功');
					this.getList();
				}catch(e){
					//TODO handle the exception
				}
				
				this.changeLeader.loading = false;
			},
			
			
			/**
			 * 更改跟进状态
			 */
			handleChangeFollowUpState(e){
				this.selectData = e;
				this.$confirm('该操作不可逆，是否确认将线索状态改为【跟进中】？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureChangeFollowUpState();
				})
			},
			async sureChangeFollowUpState(){
				try{
					let params = {
						Id: this.selectData.Id
					};
					const res = await cRMClueFollowUp(params);
					this.$message.success('操作成功');
					this.getList();
				}catch(e){
				}
			},
		},
		created() {
			this.getList();
			this.getSourceList();
			this.getEmployeeList();
		}
	}
</script>

<style lang="less" scoped>
	
.page-box{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 15px;
}
	
.clue-table ::v-deep th,
.clue-table ::v-deep tr{
	background: #fff;
}
.tag{
	width: 32px;
	height: 18px;
	background: #F56C6C;
	color: #fff;
	font-size: 12px;
	margin-left: 8px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}
.clue-dialog ::v-deep .el-dialog__body{
	padding: 10px 20px 20px;
}

.yellowBox{
	background: #FDF6EC;
	padding: 15px ;
	font-size: 14px;
	color: #FDA23C;
}
.file-box{
	border: 1px solid #DCDFE6;
	width: 500px;
	height: 172px;
	margin: 20px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>